import React from "react";
import { render } from "react-dom";
import { Route, HashRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";

render(
  <HashRouter>
    <Route exact path="/" component={App} />
  </HashRouter>,
  document.getElementById("root")
);
