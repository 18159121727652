import React from "react";
import { FaAngleDoubleUp } from "react-icons/fa";
import { Button, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <div>
      <footer className="footerContainer">
        <Container fluid>
          <Row className="d-flex flex-nowrap justify-content-between align-items-center">
            <p className="copyright flex-lg-grow-1">
              Copyright &copy; 2021 by Thiago Sanches. All Rights Reserved.
            </p>
            <Button id="topButton" aria-label="back to top of the page" className="ml-auto flex-sm-shrink-1" onClick={() => window.scrollTo(0, 0)}>
              <FaAngleDoubleUp aria-hidden="true" />
            </Button>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default Footer;
