import React, { useState } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { Nav, Navbar } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import logo from "../assets/images/tss-logo.svg";
import { FaInstagram, FaLinkedin, FaBehance, FaTwitter } from "react-icons/fa";

const Header = () => {
  const [isHamburgerOpen, setHambugerOpen] = useState(false);

  const [isNavExpanded, setNavExpanded] = useState(false);

  const [hideOnScroll, setHideOnScroll] = useState(false);

  const hiddenStyle = hideOnScroll ? "hidden" : "";

  useScrollPosition(({ prevPos, currPos }) => {
    const isVisible = currPos.y < prevPos.y;

    if(isVisible !== hideOnScroll) {
      setHideOnScroll(isVisible);
    }
  }, [hideOnScroll]);

  // toggle hambuger menu status on click
  const handleHamburgerClick = (e) => {
    e.preventDefault();
    isHamburgerOpen ? setHambugerOpen(false) : setHambugerOpen(true);
    isNavExpanded ? setNavExpanded(false) : setNavExpanded(true);
  };

  const handleNavLinkClick = (e) => {
    if (window.innerWidth < 768) {
      e.preventDefault();
      setTimeout(() => {
        setNavExpanded(false);
      }, 150);
      isHamburgerOpen ? setHambugerOpen(false) : setHambugerOpen(true);
    }
  };

  return (
    <>
      <Navbar
        expanded={isNavExpanded}
        expand="md"
        variant="light"
        bg="white"
        fixed="top"
        className={`header shadow-sm ${hiddenStyle}`}
      >
        <Navbar.Brand
          href="#/"
          onClick={() => window.scrollTo(0, 0)}
          aria-label="back to top of the page"
        >
          <img className="logoImg ml-3" src={logo} alt="Thiago Sanches' Logo" />
        </Navbar.Brand>

        <Navbar.Toggle
          className={`hamburger hamburger--collapse ${
            isHamburgerOpen ? "is-active" : ""
          }`}
          aria-controls="responsive-navbar-nav"
          children={
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          }
          onClick={handleHamburgerClick}
        ></Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <NavLink
              className="nav-link navMenu"
              to="/#projects"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              PROJECTS
            </NavLink>
            <NavLink
              className="nav-link navMenu"
              to="/#about"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              ABOUT
            </NavLink>
            <NavLink
              className="nav-link navMenu"
              to="/#contact"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              CONTACT
            </NavLink>
          </Nav>
          <Nav className="d-flex ml-auto flex-sm-row contact-icons-nav">
            <a
              href="https://www.linkedin.com/in/tssanches/"
              aria-label="Linkedin Profile"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-icon flex-shrink-0"
            >
              <FaLinkedin aria-hidden="true" className="contact-icon-content" />
            </a>
            <a
              href="https://www.behance.net/tssanches"
              aria-label="Behance Portfolio"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-icon"
            >
              <FaBehance
                aria-hidden="true"
                className="contact-icon-content"
              />
            </a>
            <a
              href="https://www.instagram.com/tsanchesto/"
              aria-label="Instagram Profile"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-icon"
            >
              <FaInstagram
                aria-hidden="true"
                className="contact-icon-content"
              />
            </a>
            <a
              href="https://twitter.com/tsanchesto"
              aria-label="Twitter Profile"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-icon"
            >
              <FaTwitter
                aria-hidden="true"
                className="contact-icon-content"
              />
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
