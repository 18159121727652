import React from 'react';
import loadable from "@loadable/component";
const About = loadable(()=> import('../components/portfolio/About'));
const Contact = loadable(()=> import('../components/portfolio/Contact'));
const Projects =  loadable(()=> import('./portfolio/Projects'));
const HeroContainer =  loadable(()=> import('./portfolio/HeroContainer'));

function MainContainer() {
    return (
        <div className="main-container">
            <HeroContainer />
            <Projects />
            <About />
            <Contact />
        </div>
    )
}

export default MainContainer;
